import React, { useState, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { reduce } from 'lodash';

import { Button, HintText, MovieDetails, Page } from 'components';
import { restartRoom, store, MovieInfo, VoteResult, getUsername, Room, isAdmin } from 'utils';

export const Results = () => {
  const { room } = store.useState();
  const { execute: runRestartRoom, isLoading } = restartRoom.useDefer();
  const [shownMovie, setShownMovie] = useState<number>();

  const movies: { movie: MovieInfo, votes: VoteResult }[] | undefined = useMemo(
    () => {
      const { voteResult } = room?.results || {};

      if (voteResult) {
        if (!shownMovie) setShownMovie(0);

        return voteResult
          .map(([id, vr]) => ({
            movie: room?.movies?.find(m => String(m.id) === id)!,
            votes: vr,
          }))
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [room],
    /* eslint-enable react-hooks/exhaustive-deps */
  )
  const MostLikedUser = useMemo(() => {
    const username = getUsername(room, room?.results?.userResult?.likedMostMovies)

    if (!username) return null;
    return <Typography>{`Liked the most movies: ${username}`}</Typography>
  }, [room]);

  const MostDislikedUser = useMemo(() => {
    const username = getUsername(room, room?.results?.userResult?.dislikedMostMovies)

    if (!username) return null
    return <Typography>{`Disliked the most movies: ${username}`}</Typography>
  }, [room]);

  const suggestedByUser = useMemo(() => reduce(
    room?.preferences,
    (x, { suggestion }, uid) => ({
      ...x,
      ...(suggestion ? { [suggestion]: getUsername(room, uid) } : {})
    }),
    {} as { [movieId: string]: string | undefined },
  ), [room]);

  return (
    <Page loading={!room?.results?.voteResult}>
      <Typography variant='h4' style={{ marginBottom: '12px' }}>Hivemind picked</Typography>
      {movies && movies.map(({ movie, votes }, i) => (
        <Accordion
          expanded={shownMovie === i}
          onChange={(_, expanded) => setShownMovie(expanded ? i : undefined)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
              <Typography style={{ maxWidth: '80%' }}>{movie.title}</Typography>
              <HintText style={{ minWidth: '65px' }}>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  {votes.LIKE || 0}<CheckIcon /> {votes.DISLIKE || 0}<CloseIcon />
                </Box>
              </HintText>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {suggestedByUser[movie.id!.toString()] && (
              <Box display='flex' flexDirection='row' justifyContent='center' marginBottom='12px'>
                <Chip color='info' label={`${suggestedByUser[movie.id!.toString()]} suggested`} />
              </Box>
            )}
            <MovieDetails movie={movie} showProvider />
          </AccordionDetails>
        </Accordion>
      ))
      }
      <Typography variant='h4' style={{ margin: '12px 0' }}>Fun Stats</Typography>
      {MostLikedUser}
      {MostDislikedUser}
      <UserLikenessMap room={room} />
      {
        isAdmin() ? (
          <Button
            variant='contained'
            loading={isLoading}
            style={{ marginTop: '12px' }}
            onClick={() => runRestartRoom({ roomKey: room!.roomKey })}
          >
            Again!
          </Button>
        ) : (
          <HintText style={{ marginTop: '12px' }}>
            Host can restart the room
          </HintText>
        )
      }
    </Page >
  )
}

const UserLikenessMap = ({ room }: { room?: Room }) => {
  const usernameMap = room?.connectedUsers?.reduce(
    (x, { id, username }) => ({ ...x, [id]: username }),
    {} as { [id: string]: string | undefined },
  )

  if (!usernameMap) return null;

  const sortedLikenesses = Object.entries(
    reduce(
      room?.results?.userResult?.userLikenessMap,
      (x, otherUids, uid) => {
        const me = usernameMap[uid];
        Object.entries(otherUids).forEach(([otherUid, likness]) => {
          const them = usernameMap[otherUid]
          if (!(`${me} - ${them}` in x) && !(`${them} - ${me}` in x)) {
            x[`${me} - ${them}`] = likness;
          }
        })
        return x;
      },
      {} as { [tuple: string]: number },
    )
  ).sort(([_aId, aLikeness], [_bId, bLikeness]) => bLikeness - aLikeness)


  return (
    <Box>
      <Typography variant='h6'>Player similarity</Typography>
      {sortedLikenesses.map(([users, likeness]) => (
        <Typography key={users}>{`${users}: ${(likeness * 100).toFixed(0)}%`}</Typography>
      ))}
    </Box>
  )
}
