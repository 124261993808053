import firebase from 'firebase/app';
import { Store } from 'pullstate';
import { Room, User } from 'utils';

import { database } from 'utils';

export interface IStore {
  uid?: string,
  room?: Room,
  user?: User,
}
export const store = new Store<IStore>({})

// Auth
const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
  (user: firebase.User | null) => store.update(s => { s.uid = user?.uid || undefined }),
);

// User
let unsubFirebaseUser = () => { };
const removeUserReaction = store.createReaction(
  s => s.uid,
  (uid, state, _, prevUid) => {
    unsubFirebaseUser();

    // we may have stored some data here prior to
    // firebase callbacks firing on first retrieval
    if (!!prevUid) {
      state.user = undefined;
      state.room = undefined;
    }

    if (uid) {
      const dbUser = database.child(`users/${uid}`);
      const callback = dbUser.on('value',
        (snapshot: firebase.database.DataSnapshot) => {
          store.update(s => { s.user = snapshot.val(); })
        })

      unsubFirebaseUser = () => dbUser.off('value', callback);
    }
  }
)

// Room
let unsubFirebaseRoom = () => { }
const removeRoomReaction = store.createReaction(
  s => s.user?.recentRoomID,
  (recentRoomID, state, _, prevRecentRoomID) => {
    unsubFirebaseRoom();

    // room state is updated prior to user.recentRoomID
    // being set to provide better UX
    if (!!prevRecentRoomID) {
      state.room = undefined;
    }

    if (recentRoomID) {
      const dbRoom = database.child(`rooms/${recentRoomID}`);
      const callback = dbRoom.on('value',
        (snapshot: firebase.database.DataSnapshot) => {
          store.update(s => { s.room = snapshot.val() })
        },
      );

      unsubFirebaseRoom = () => dbRoom.off('value', callback);
    }
  }
)

export const cleanupStoreWatchers = () => {
  // Auth
  unregisterAuthObserver();
  // User
  unsubFirebaseUser();
  removeUserReaction();
  //Room
  unsubFirebaseRoom();
  removeRoomReaction();
}
