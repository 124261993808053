import React from 'react';
import firebase from 'firebase/app';
import { Box, Menu, MenuItem } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { store, leaveRoom } from 'utils';
import { Button } from 'components';

export const StatusBar = () => {
  const { user, room } = store.useState();
  const [leaveStarted, leaveFinished] = leaveRoom.useWatch({ roomKey: room?.roomKey || '' })
  const leaveLoading = leaveStarted && !leaveFinished;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleUsernameClick = (e: any) => setAnchorEl(e.currentTarget);
  const handleSignOut = () => {
    firebase.auth().signOut();
    history.replace('/');
    setAnchorEl(null);
  };

  const onLeaveRoom = () => {
    leaveRoom.run({ roomKey: room!.roomKey });
    history.replace('/');
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='flex-end'
      margin='8px'
    >
      {room ? (
        <Button variant='outlined' onClick={onLeaveRoom} loading={leaveLoading}>
          Leave
        </Button>
      ) : null}
      {user?.username && (
        <>
          <Button style={{ marginLeft: '8px' }} onClick={handleUsernameClick}>
            {user.username}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => { setAnchorEl(null); }}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
          >
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
        </>
      )}
    </Box>
  )
}
