import React from 'react';
import { Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Page } from 'components'

const DEFAULT_MESSAGE = 'Something went wrong';
export interface IInvalidStateState {
  message?: string
}
export const InvalidState = () => {
  const history = useHistory<IInvalidStateState>();

  const message = history.location.state?.message || DEFAULT_MESSAGE;

  const navigateHome = () => history.replace('/')
  return (
    <Page>
      <Typography variant='h5'>{message}</Typography>
      <Button
        variant='contained'
        color='primary'
        style={{ marginTop: '12px' }}
        onClick={navigateHome}
      >
        Return Home
      </Button>
    </Page>
  );
};