import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { StyledFirebaseAuth } from 'react-firebaseui';
import * as firebaseui from 'firebaseui';
import firebase from 'firebase/app';

import { db, onEnter, store, database } from 'utils';

export const UserAuth = ({ children }: any) => {
  const { uid, user } = store.useState();
  const [text, setText] = useState<string>('');
  const [updateUsernameLoading, setUpdateUsernameLoading] = useState<boolean>(false);

  if (!uid) {
    const uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        // The optional chaining is purely to support mocking in the test
        firebase.auth.GoogleAuthProvider?.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider?.PROVIDER_ID,
        firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
      ],
    };

    return (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        marginTop='20%'
      >
        <Typography variant='h5'>Sign in</Typography>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </Box>
    )
  } else if (!user?.username) {
    const submit = async () => {
      setUpdateUsernameLoading(true);
      await database.update({ ...db.users(uid).username.val(text) })
      setUpdateUsernameLoading(false);
    };

    return (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        marginTop='20%'
      >
        <Typography variant='h5'>Create a username</Typography>
        <Box marginTop='12px' display='flex'>
          <TextField
            placeholder='cringe dragon'
            value={text}
            onChange={e => setText(e.target.value)}
            onKeyDown={onEnter(submit)}
            inputProps={{ 'data-testid': 'usernameInput' }}
            size='small'
          />
          <Button
            onClick={submit}
            color='primary'
            variant='contained'
            disabled={!text || updateUsernameLoading}
            size='small'
            style={{ marginLeft: '6px' }}
          >
            {updateUsernameLoading ? 'Submitting...' : 'Submit'}
          </Button>
        </Box>
      </Box>
    );
  }

  return children;
};
