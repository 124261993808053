import firebase from 'firebase';
import { MovieDb } from 'moviedb-promise';
const firebaseConfig = require('../firebase_config.json');

firebase.initializeApp(firebaseConfig);
if (process.env.NODE_ENV === 'development') {
  firebase.functions().useEmulator("localhost", 5001);
  firebase.database().useEmulator("localhost", 9000);
  firebase.auth().useEmulator("http://localhost:9099");
}

export const database = firebase.database().ref();
export const moviedb = new MovieDb(process.env.REACT_APP_TMDB_API_KEY!);
