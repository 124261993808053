import React from 'react';
import { Snackbar } from '@mui/material';

interface IToastProps {
  text: string
  open: boolean
  onClose: () => void
}
export const Toast = ({ text, open, onClose }: IToastProps) => (
  <Snackbar
    autoHideDuration={1500}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    style={{ width: 'fit-content' }}
    message={text}
    open={open}
    onClose={onClose}
  />
)
