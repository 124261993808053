import React from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';

import { Home, VotingProcess, InvalidState } from 'pages';
import { UserAuth } from 'hoc';

import 'firebase/auth';
import './App.css';

const App = () => {
  return (
    <Router>
      <Switch>
        <Redirect exact from='/room' to='/' />
        <Route path='/room/:roomKey'>
          <UserAuth>
            <VotingProcess />
          </UserAuth>
        </Route>
        <Route path='/invalid'>
          <InvalidState />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Router>
  )
};

export default App;
