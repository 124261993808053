import React from 'react';
import { CircularProgress } from '@mui/material';
import { useParams, Redirect } from 'react-router-dom'

import {
  Lobby,
  Results,
  MovieVoting,
  MoviePreferences,
  IInvalidStateState,
} from 'pages';
import { RoomState, store, joinRoom } from 'utils';
import { Page } from 'components';

export const VotingProcess = () => {
  const { room } = store.useState();
  const { roomKey } = useParams<{ roomKey: string }>();
  const [finished, result] = joinRoom.useBeckon({ roomKey });

  if (!finished) {
    return (
      <Page>
        <CircularProgress />
      </Page>
    );
  } else if (result.error) {
    return (
      <Redirect
        to={{
          pathname: '/invalid',
          state: { message: 'There was a problem joining this room' } as IInvalidStateState,
        }}
      />
    )
  }

  switch (room?.state) {
    case RoomState.ACTIVE:
      return <Lobby />
    case RoomState.MOVIE_PREF:
      return <MoviePreferences />
    case RoomState.MOVIE_VOTING:
      return <MovieVoting />
    case RoomState.RESULTS:
      return <Results />
    default:
      break;
  }

  return (
    <Redirect
      to={{
        pathname: '/invalid',
        state: { message: 'How did you get here?' } as IInvalidStateState,
      }}
    />
  );
}
