import { Room, store } from 'utils';

export const onEnter = (func: () => void) => (e: any) => {
  if (e.key === 'Enter') {
    func();
  }
};

export const isAdmin = () => {
  const { room, user } = store.getRawState();
  if (!room?.admin || !user) return undefined;
  else return room.admin?.id === user.id;
}

// Maybe we can use this later
// const LONG_PRESS_DURATION = 500;
// import { useState, useEffect } from 'react';
// import { ButtonBaseProps } from '@mui/material'
// export const useLongPress = (
//   longPressCallback?: () => void,
//   pressCallback?: () => void,
// ): Partial<ButtonBaseProps> => {
//   const [startPress, setStartPress] = useState(false);
//   const [callbackRan, setCallbackRan] = useState(false);

//   useEffect(() => {
//     let timerId: number | undefined = undefined;
//     if (startPress && longPressCallback) {
//       timerId = window.setTimeout(() => {
//         if (callbackRan) return;
//         longPressCallback();
//         setCallbackRan(true);
//       }, LONG_PRESS_DURATION);
//     } else {
//       window.clearTimeout(timerId);
//     }

//     return () => window.clearTimeout(timerId)
//   }, [longPressCallback, startPress, callbackRan]);

//   if (!longPressCallback && !pressCallback) return {};
//   if (!longPressCallback && pressCallback) return ({ onClick: pressCallback })

//   return {
//     onMouseDown: () => setStartPress(true),
//     onMouseUp: () => {
//       if (!callbackRan && pressCallback) pressCallback();
//       setStartPress(false);
//       setCallbackRan(false);
//     },
//     onMouseLeave: () => {
//       setStartPress(false);
//       setCallbackRan(false);
//     },
//     onTouchStart: () => setStartPress(true),
//     onTouchEnd: () => {
//       if (!callbackRan && pressCallback) pressCallback();
//       setStartPress(false);
//       setCallbackRan(false);
//     },
//   };
// }

export const getUsername = (room?: Room, uid?: string) => room && uid
  ? room.connectedUsers?.find(({ id }) => id === uid)?.username
  : undefined
