
interface ProviderData { name: string, ids: string[], logoPath: string }

// const logoHeight = '40px';
// TODO: make this more robust
// - pull data from tmdb
// - allow for searching through providers
// - intelligent combination of providers?
// { name: [ids] 
export const providers: ProviderData[] = [
  {
    name: 'Netflix',
    ids: ['8'],
    logoPath: '/9A1JSVmSxsyaBK4SUFsYVqbAYfW.jpg',
    // image: <NetflixLogo height={logoHeight />,
  },
  {
    name: 'Hulu',
    ids: ['15'],
    logoPath: '/giwM8XX4V2AQb9vsoN7yti82tKK.jpg',
    // image: <HuluLogo height={logoHeight />,
  },
  {
    name: 'Amazon Prime',
    ids: ['9', '119'],
    logoPath: '/68MNrwlkpF7WnmNPXLah69CR5cb.jpg',
    // image: <AmazonPrimeVideoLogo height={logoHeight />
  },
  {
    name: 'Disney+',
    ids: ['390', '337'],
    logoPath: '/dgPueyEdOwpQ10fjuhL2WYFQwQs.jpg',
    // image: <DisneyPlusLogo height={logoHeight />,
  },
  {
    name: 'Apple TV+',
    ids: ['350'],
    logoPath: '/A3WLxoSkmuxwaQkpfwL6H8WwWwM.jpg'
    // image: <AppleTVLogo height={logoHeight />,
  },
  // {
  //   name: 'Crunchyroll',
  //   ids: [283],
  //   logoPath: 
  //   // image: <CrunchyRollLogo height={logoHeight />,
  // },
]

export interface Genre {
  id: string 
  name: string
  phrasing: string
  priority?: boolean
}
export const genres: Genre[] = [
  { id: '28', name: 'Action', phrasing: '💥 an action movie', priority: true },
  { id: '12', name: 'Adventure', phrasing: '⛰️ an adventure movie', priority: true },
  { id: '16', name: 'Animation', phrasing: '🖌️ an animated movie', priority: true },
  { id: '35', name: 'Comedy', phrasing: '😂 a comedy', priority: true },
  { id: '14', name: 'Fantasy', phrasing: '🦄 a fantasy movie', priority: true },
  { id: '27', name: 'Horror', phrasing: '🧟 a horror movie', priority: true },
  { id: '10749', name: 'Romance', phrasing: '💞 a romantic movie', priority: true },
  { id: '878', name: 'Science Fiction', phrasing: '🧪 a sci-fi movie', priority: true },
  { id: '53', name: 'Thriller', phrasing: '🎢 a thriller', priority: true },
  { id: '80', name: 'Crime', phrasing: '🔪 a movie about crime' },
  { id: '99', name: 'Documentary', phrasing: '🎥 a documentary' },
  { id: '18', name: 'Drama', phrasing: '🎭 a drama' },
  { id: '10751', name: 'Family', phrasing: '👪 a family movie' },
  { id: '36', name: 'History', phrasing: '🏰 a historical movie' },
  { id: '10402', name: 'Music', phrasing: '🎵 a musical' },
  { id: '9648', name: 'Mystery', phrasing: '🕵🏽 a mystery' },
  { id: '10770', name: 'TV Movie', phrasing: '📺 a TV movie' },
  { id: '10752', name: 'War', phrasing: '🪖 a movie on war' },
  { id: '37', name: 'Western', phrasing: '🤠 a western' },
]
