import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';

import { onEnter, createRoom } from 'utils';
import { Page } from 'components';

export const Home = () => {
  const [roomKey, setRoomKey] = useState<string>('');
  // TODO: handle error
  const { execute: runCreateRoom, isLoading, payload } = createRoom.useDefer()
  const history = useHistory();

  useEffect(() => {
    const roomKeyDB = payload?.room.roomKey;

    if (roomKeyDB) history.push(`/room/${roomKeyDB}`);
  }, [payload, history])

  const joinRoom = () => history.push(`/room/${roomKey}`);

  const onRoomKeyChange = (e: any) => {
    const enteredValue: string = e.target.value;

    // for easier typability
    const newValue = enteredValue.toLowerCase().replace(' ', '-');
    setRoomKey(newValue);
  }

  return (
    <Page>
      <Typography variant="h2">
        Hivemind
      </Typography>
      <Box display='flex' alignItems='center' margin='24px 0 24px 0'>
        <Button
          variant='contained'
          color='primary'
          onClick={() => runCreateRoom()}
          size='small'
        >
          Create
        </Button>
        <Typography variant='body1' style={{ marginLeft: '6px' }}>a new room</Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box marginBottom='24px'>
          {isLoading? (
            <CircularProgress />
          ) : (
            <Typography variant="h5">
              or
            </Typography>
          )}
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Button
            variant='contained'
            color='primary'
            onClick={joinRoom}
            disabled={!roomKey}
            size='small'
            style={{ maxHeight: '36px', marginRight: '6px' }}
          >
            Join
          </Button>
          <TextField
            label='an existing room'
            value={roomKey}
            onChange={onRoomKeyChange}
            onKeyDown={onEnter(joinRoom)}
            variant='outlined'
            size='small'
            inputProps={{ 'data-testid': 'roomKeyInput' }}
          />
        </Box>
      </Box>
    </Page>
  );
};
