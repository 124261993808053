import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
  Box,
  styled,
  colors,
  Checkbox as CheckboxComponent,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { flatten, intersection } from 'lodash';
import { FileCopyOutlined, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'

import {
  Button,
  GridTags,
  HintText,
  Page,
  ITagProps,
  Toast,
} from 'components';
import {
  db,
  store,
  Record,
  isAdmin,
  database,
  nextRoomState,
} from 'utils';

const StyledTag = styled(Typography)({
  padding: '12px',
  borderRadius: '10px',
  border: '1px solid lightGrey',
})

interface Provider {
  display_priority: number,
  logo_path: string,
  provider_name: string,
  provider_id?: number,
  providerIds?: string[],
}

export const Lobby = () => {
  const { user, room } = store.useState();

  // Lobby will only be displayed when room.state === ACTIVE
  const { roomKey } = room!;
  const [nextRoomStateStarted, nextRoomStateFinished] = nextRoomState.useWatch({ roomKey });
  const nextRoomStateLoading = nextRoomStateStarted && !nextRoomStateFinished;
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [selectedProviders, setSelectedProviders] = useState<Array<Provider>>([]);
  const [providers, setProviders] = useState<Array<Provider>>([]);

  const copyRoomLink = () => {
    // optional chaining purely for test
    navigator.clipboard?.writeText(window.location.href);
    setSnackbarOpen(true);
  }

  const { red, purple, deepPurple, indigo, blue, teal, deepOrange } = colors;
  const colorList = [red, purple, deepPurple, indigo, blue, teal, deepOrange]

  const onServicesChange = useCallback((_, val: Provider[]) => {
    if (!user?.recentRoomID || nextRoomStateLoading) return;

    setSelectedProviders(val)

    const providerIds = flatten(val.map(v => v.providerIds!));
    database.update({
      ...db.rooms(user!.recentRoomID!).providerIds.val(providerIds),
      ...db.users(user.id).preferences.providerIds.val(providerIds),
    })
  }, [user, nextRoomStateLoading]);

  useEffect(() => {
    const selectedProviders = user?.preferences?.providerIds;
    if (!providers || !selectedProviders) return;

    setSelectedProviders(
      providers.filter(p => intersection(
        p.providerIds,
        selectedProviders,
      ).length)
    )
  }, [providers])

  useEffect(() => {
    (async () => {
      const data = await fetch(
        `https://api.themoviedb.org/3/watch/providers/movie?api_key=${process.env.REACT_APP_TMDB_API_KEY}&watch_region=US`
      ).then(response => response.json())
      setProviders(Object.values(
        (data.results as Array<Provider>).reduce((x, provider) => {
          const cur = x[provider.provider_name];
          if (cur) {
            cur.providerIds?.push(`${provider.provider_id}`);
          } else {
            const { provider_id, ...rest } = provider;
            x[provider.provider_name] = { ...rest, providerIds: [`${provider.provider_id}`] }
          }

          return x;
        }, {} as Record<Provider>)
      ));
    })();
  }, [])

  const usernameTags = useMemo(() => room?.connectedUsers?.map<ITagProps>(({ username }, i) => ({
    label: username!,
    style: { backgroundColor: colorList[i % colorList.length]['500'] },
    textStyle: { color: 'white' },
  })) || [], [room?.connectedUsers])

  const allowBegin = isAdmin() && !nextRoomStateLoading

  return (
    <Page>
      <Box display='flex' flexDirection='column' marginBottom='24px'>
        <Typography variant='body1'>Room name</Typography>
        <StyledTag variant='h4'>
          {roomKey}
        </StyledTag>
        <Button
          onClick={copyRoomLink}
          startIcon={<FileCopyOutlined />}
          style={{ alignSelf: 'flex-end' }}
          size='small'
        >
          Copy Link
        </Button>
      </Box>
      <GridTags tags={usernameTags} />
      {isAdmin() && (
        <>
          <h3>Select your available services</h3>
          <Autocomplete
            multiple
            loading={providers.length === 0}
            options={providers}
            disableCloseOnSelect
            getOptionLabel={(option) => option.provider_name}
            onChange={onServicesChange}
            value={selectedProviders}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <CheckboxComponent
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.provider_name}
              </li>
            )}
            style={{ width: '80%', maxWidth: '500px' }}
            renderInput={(params) => (
              <TextField {...params} label="Streaming services" placeholder="ex. Netflix" />
            )}
          />
        </>
      )}
      {!isAdmin() ? (
        <Box marginTop='12px'>
          <HintText>Host must select streaming services and begin</HintText>
        </Box>
      ) : (
        <Button
          onClick={() => nextRoomState.run({ roomKey })}
          disabled={!allowBegin}
          style={{ marginTop: '16px' }}
          color='primary'
          variant='contained'
          loading={nextRoomStateLoading}
        >
          Next
        </Button>
      )}
      <Toast
        text='Link copied!'
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </Page>
  );
};
