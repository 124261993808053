import React from 'react';
import { Badge, BadgeProps } from '@mui/material';

interface IMultiBadgeProps {
  children: React.ReactNode
  posNum?: number
  negNum?: number
  posProps?: BadgeProps
  negProps?: BadgeProps
}
export const MultiBadge = ({ children, posNum, negNum, posProps, negProps }: IMultiBadgeProps) => (
  <Badge
    badgeContent={posNum || 0}
    color='success'
    style={{ marginLeft: '8px', marginTop: '12px' }}
    { ...posProps }
  >
    <Badge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={negNum || 0}
      color='error'
      { ...negProps }
    >
      {children}
    </Badge>
  </Badge>
);
