import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { ReadyButton, Page, GenrePicker, MoviePicker, Toast } from 'components';
import {
  db,
  store,
  moviedb,
  database,
  MovieInfo,
} from 'utils';

export const MoviePreferences = () => {
  const { user, room } = store.useState();
  const [suggestedMovie, setSuggestedMovie] = useState<MovieInfo | null>(null);
  const [toastOpen, setToastOpen] = useState(false);

  useEffect(() => {
    if (!user?.id) return;
    const myMovieId = room?.preferences?.[user.id]?.suggestion

    if (!suggestedMovie && myMovieId) {
      (async () => await setSuggestedMovie(
        await moviedb.movieInfo(myMovieId)
      ))();
    }
  /* eslint-disable react-hooks/exhaustive-deps */
  }, [room?.preferences])
  /* eslint-enable react-hooks/exhaustive-deps */

  const suggestMovie = async (movie: MovieInfo | null) => {
    setSuggestedMovie(movie)
    await database.update({
      ...db.rooms(room!.id).preferences(user!.id).suggestion.val(movie?.id?.toString() || null),
    })
    setToastOpen(true);
  }

  return (
    <Page>
      <Typography variant='h4'>What are you feeling?</Typography>
      <GenrePicker />
      <Typography
        fontSize={18}
        style={{ margin: '12px 0 12px 0' }}
      >
        Throw your own movie into the mix!
      </Typography>
      <MoviePicker
        suggestedMovie={suggestedMovie}
        label='Suggest a movie'
        onSelect={suggestMovie}
      />
      <ReadyButton style={{ marginTop: '24px' }}/>
      <Toast
        text='Success!'
        open={toastOpen}
        onClose={() => setToastOpen(false)}
      />
    </Page>
  )
}
