import React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material';

export interface IButtonProps extends ButtonProps {
  loading?: boolean
}
export const Button = (props: IButtonProps) => {
  const { loading, disabled, ...rest } = props;
  return (
    <MuiButton
      {...rest}
      startIcon={(loading && <CircularProgress size={16} />) || props.startIcon}
      disabled={disabled || loading}
    />
  )
};
