import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import shuffle from 'lodash/shuffle';

import { Page, MovieDetails } from 'components';
import {
  store,
  VoteTypes,
  MovieInfo,
  movieVote,
} from 'utils';

export const MovieVoting = () => {
  const { room, user } = store.useState();
  const [movies, setMovies] = useState<MovieInfo[] | undefined>()
  const [currentMovieIndex, setCurrentMovieIndex] = useState<number>(0);
  const [voteLoading, setVoteLoading] = useState<boolean>(false);
  const movie = movies?.[currentMovieIndex];

  // grab movies to show once they're in the database
  useEffect(() => {
    if (room?.movies && !movies) {
      const votedMovies = Object.values(room?.votes || {})
        .filter(({ uid }) => uid === user?.id)
        .map(({ tmdbID }) => tmdbID);
      const unvotedMovies = room.movies.filter(({ id }) => !votedMovies.includes(String(id)))

      setMovies(shuffle(unvotedMovies));
    }

  }, [room?.movies, room?.votes, user?.id, movies])

  const voteAndNext = (voteType: VoteTypes) => {
    if (voteLoading) return;
    // TODO: handle error
    setVoteLoading(true);
    movieVote.run({
      roomKey: room!.roomKey,
      vote: { tmdbID: String(movie?.id), voteType },
    }).finally(() => setVoteLoading(false))
    setCurrentMovieIndex(currentMovieIndex + 1)
  }

  // TODO: movie loading status, what happens if loading process fails?
  if (currentMovieIndex === movies?.length) {
    return (
      <Page>
        <Typography>Waiting for everyone to finish voting</Typography>
      </Page>
    )
  } else if (!movie) {
    return (
      <Page>
        <CircularProgress />
        {/** Include some loading messages */}
      </Page>
    )
  }

  return (
    <Page>
      <MovieDetails
        movie={movie}
        loading={voteLoading}
        onApprovalVote={() => voteAndNext(VoteTypes.LIKE)}
        onDisapprovalVote={() => voteAndNext(VoteTypes.DISLIKE)}
      />
    </Page>
  );
}
