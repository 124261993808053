import firebase from 'firebase/app';
import {
  createAsyncAction,
  errorResult,
  IPullstateAllStores,
  successResult,
  TPullstateAsyncAction,
} from 'pullstate';
import { Room, store, Vote } from 'utils';

interface IRoomActionInput {
  roomKey: string;
}
interface IRoomActionOutput {
  error?: string
  room: Room,
}

interface IMovieVoteInput {
  roomKey: string
  vote: Partial<Vote> // uid inserted on the backend
}

export const createRoom = createAsyncAction<{}, IRoomActionOutput>(
  createPullStateAction('createRoom'),
)
export const joinRoom = createAsyncAction<IRoomActionInput, IRoomActionOutput>(
  createPullStateAction('joinRoom'),
  {
    cacheBreakHook: () => true,
    postActionHook: ({ result }) => {
      if (!result.error) {
        store.update(s => { s.room = result.payload.room });
      }
    }
  }
)
export const leaveRoom = createAsyncAction<IRoomActionInput, IRoomActionOutput>(
  createPullStateAction('leaveRoom')
)
export const nextRoomState = createAsyncAction<IRoomActionInput, IRoomActionOutput>(
  createPullStateAction('nextRoomState')
)
export const movieVote = createAsyncAction<IMovieVoteInput, void>(
  createPullStateAction('movieVote')
)
export const restartRoom = createAsyncAction<IRoomActionInput, IRoomActionOutput>(
  createPullStateAction('restartRoom')
)

export const userReady = createAsyncAction<{ ready: boolean }, IRoomActionOutput>(
  createPullStateAction('userReady')
);

function createPullStateAction<Input, Output>(
  name: string,
): TPullstateAsyncAction<Input, Output, string, any, IPullstateAllStores> {
  return async (data: Input) => {
    let result: firebase.functions.HttpsCallableResult;
    try {
      result = await firebase.functions().httpsCallable(name)(data);
    } catch (e: any) {
      return errorResult([], e.message)
    }

    return successResult(result.data)
  }
}
