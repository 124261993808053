import React from 'react';
import { Grid } from '@mui/material';

import { Tag, ITagProps } from 'components'

interface GridTagsProps {
  tags: ITagProps[]
}
export const GridTags = ({ tags }: GridTagsProps) => (
  <Grid container style={{ maxWidth: '500px', justifyContent: 'center' }}>
    {tags.map((tag, i) => (
      <Tag key={`${tag.label}-${i}`} { ...tag }/>
    ))}
  </Grid>
);
