import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { Check as CheckIcon } from '@mui/icons-material';

import { Button, IButtonProps } from 'components';
import { database, store, userReady } from 'utils';

const getNumReady = (readyUsers: { [uid: string]: boolean } | null) => readyUsers
  ? Object.values(readyUsers).filter(x => !!x).length
  : 0

export const ReadyButton = (props: IButtonProps) => {
  const [ready, setReady] = useState<boolean>(false);
  const [numReady, setNumReady] = useState<number>(0);
  const { room, user } = store.useState();
  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedReady = useCallback(debounce(userReady.run, 250), []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (!room || !user) return;

    const readyUsers = database.child(`rooms/${room.id}/readyUsers`)
    const callback = readyUsers
      .on('value', snapshot => {
        const readyUsers: { [uid: string]: boolean } | null = snapshot.val();
        const myReadyStatus = !!readyUsers?.[user.id];
        if (myReadyStatus !== ready) setReady(myReadyStatus);

        setNumReady(getNumReady(readyUsers));
      });

    return () => readyUsers.off('value', callback);
  }, []);

  const onClick = useCallback(() => {
    if (!user) return;

    debouncedReady({ ready: !ready })
    setReady(!ready);
    setNumReady(getNumReady({
      ...(room?.readyUsers || {}),
      [user.id]: !ready,
    }))
  }, [ready, debouncedReady, user, room?.readyUsers])

  return (
    <Button
      endIcon={ready ? <CheckIcon /> : undefined}
      color={ready ? 'success' : undefined}
      variant='contained'
      onClick={onClick}
      {...props}
    >
      {numReady}/{room?.connectedUsers?.length || 0} Ready
    </Button>
  )
}