import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Chip,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material'
import { reduce, forEach, set } from 'lodash';

import { db, database, store, genres, VoteTypes } from 'utils'
import { MultiBadge, Button } from 'components';

export const GenrePicker = () => {
  const { room, user } = store.useState();
  const [want, setWant] = useState<boolean>(true);
  const [genreSelection, setGenreSelection] = useState<string>('')

  useEffect(() => {
    if (!(room?.id && user?.id)) return;

    database.update({
      ...db.rooms(room.id).preferences(user.id).genreVotes(genreSelection).val(
        genreSelection
          ? want ? VoteTypes.LIKE : VoteTypes.DISLIKE
          : null
      ),
    })
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [genreSelection, want])
  /* eslint-enable react-hooks/exhaustive-deps */

  const voteChips = useMemo(() => reduce(
    room?.preferences,
    (x, pref, uid) => {
      forEach(pref.genreVotes, (voteType, genreId) => {
        const val = x[genreId]?.[voteType] || 0;
        set(x, `${genreId}.${voteType}`, val + 1)
      })
      return x;
    },
    {} as { [genreId: string]: { [t in VoteTypes]: number } },
  ), [room?.preferences])

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      style={{ margin: '0 0 24px 0', width: '90%' }}
    >
      <Grid container style={{
        marginBottom: '12px',
        maxWidth: '500px',
      }}>
        {genres.map(({ id, name }) => {
          const votes = voteChips[id];
          if (!votes) return null;
          return (
            <MultiBadge
              key={id}
              posNum={votes[VoteTypes.LIKE]}
              negNum={votes[VoteTypes.DISLIKE]}
            >
              <Chip label={name} />
            </MultiBadge>
          )
        })}
      </Grid>
      <Box display='flex' flexDirection='row' alignItems='center' marginBottom='12px'>
        <Typography fontSize={15}>I</Typography>
        <Button
          color={want ? 'success' : 'error'}
          onClick={() => setWant(!want)}
          size='small'
        >
          {want ? 'want' : 'don\'t want'}
        </Button>
        <Typography fontSize={15}>to watch&nbsp;</Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        style={{ width: '100%' }}
      >
        <FormControl fullWidth>
          <InputLabel>movie genre</InputLabel>
          <Select<string>
            label='movie genre'
            value={genreSelection}
            MenuProps={{ PaperProps: { sx: { maxHeight: 325 } } }}
            onChange={e => setGenreSelection(e.target.value)}
          >
            {genres.map(g => (
              <MenuItem key={g.id} value={g.id}>{g.phrasing}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {genreSelection && (
          <IconButton
            style={{ marginLeft: '4px', height: '30px', width: '30px' }}
            onClick={() => setGenreSelection('')}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}
