import React from 'react';
import { ButtonBase, styled, Typography } from '@mui/material';

const StyledButton = styled(ButtonBase)({
  margin: '4px',
  width: '100px',
  padding: '8px',
  borderRadius: '10px',
  border: '1px solid lightGrey',
})
export interface ITagProps {
  label: string
  onClick?: () => void
  style?: React.CSSProperties
  textStyle?: React.CSSProperties
}
export const Tag = ({ label, onClick, style, textStyle }: ITagProps) => {

  return (
    <StyledButton
      style={style}
      disabled={!onClick}
      onClick={onClick}
    >
      <Typography style={{ textAlign: 'center', ...textStyle }}>
        {label}
      </Typography>
    </StyledButton>
  );
}
