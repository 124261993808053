import React, { useState, useMemo } from 'react';
import { Box, Typography, Grid, Chip } from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  YouTube as YouTubeIcon,
  Movie as MovieIcon,
} from '@mui/icons-material'

import { providers, MovieInfo } from 'utils';
import { Button } from 'components';

const IMAGE_PREFIX = 'http://image.tmdb.org/t/p';

interface IMovieDetailsProps {
  movie: MovieInfo,
  loading?: boolean
  showProvider?: boolean
  onApprovalVote?: () => void
  onDisapprovalVote?: () => void
}
export const MovieDetails = ({
  movie,
  loading,
  showProvider,
  onApprovalVote,
  onDisapprovalVote,
}: IMovieDetailsProps) => {
  const [youtubeLink, setYoutubeLink] = useState<string | undefined>();
  const [providerSrc, setProviderSrc] = useState<string | undefined>();

  // TODO: flatten database structure to prevent the entire object
  // changing on updates to children
  //
  // Get the first official trailer from Youtube
  useMemo(() => {
    setYoutubeLink(undefined);

    const trailerCode = movie?.videos?.results?.find(v => v.type === 'Trailer')?.key

    if (trailerCode) setYoutubeLink(`https://youtu.be/${trailerCode}`)
  }, [movie])

  // set the provider 
  useMemo(() => {
    setProviderSrc(undefined)
    if (!(movie && showProvider)) return;

    // Find intersection of the two arrays, i.e. the first provider
    // which matches the local array with the movie's provider id array
    const logoPath = providers.find(
      ({ ids }) => ids.filter(
        id => movie.providerIds?.includes(id)
      ).length > 0
    )?.logoPath;

    if (logoPath) setProviderSrc(`${IMAGE_PREFIX}/w45${logoPath}`);
  }, [movie, showProvider])

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <img src={`${IMAGE_PREFIX}/w300${movie.poster_path}`} alt={movie.title} />
      {onApprovalVote && onDisapprovalVote ? (
        <Box marginY='24px'>
          <Button
            size='large'
            variant='contained'
            color='error'
            loading={loading}
            onClick={onDisapprovalVote}
            startIcon={<CloseIcon />}
            style={{ marginRight: '24px' }}
          >
            Nah
          </Button>
          <Button
            size='large'
            variant='contained'
            color='success'
            loading={loading}
            onClick={onApprovalVote}
            startIcon={<CheckIcon />}
          >
            Cool
          </Button>
        </Box>
      ) : null}
      <Box>
        {youtubeLink && (
          <Button
            startIcon={<YouTubeIcon />}
            onClick={() => window.open(youtubeLink, '_blank')}
            variant='outlined'
            size='small'
          >
            Trailer
          </Button>
        )}
        {movie.imdb_id && (
          <Button
            startIcon={<MovieIcon />}
            size='small'
            onClick={() => window.open(`https://www.imdb.com/title/${movie.imdb_id}`, '_blank')}
            variant='outlined'
            style={{ margin: '12px' }}
          >
            IMDb
          </Button>
        )}
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box display='flex' flexDirection='row' >
          <Typography variant='h4' style={{ marginBottom: '12px' }}>{movie.title}</Typography>
          &nbsp; &nbsp;
          {showProvider && providerSrc && (
            <img src={providerSrc} alt='provider' width='25px' height='25px' style={{ marginTop: '8px' }} />
          )}
        </Box>
        <Box display='flex' alignItems='center' marginBottom='12px'>
          <Grid container style={{
            marginBottom: '12px',
            maxWidth: '500px',
          }}>
            {movie.genres?.map(({ name }) => (
              <Chip key={name} label={name} style={{ margin: '4px' }} />
            ))}
          </Grid>
        </Box>
        <Typography variant='body1'>{movie.overview}</Typography>
      </Box>
    </Box>
  )
}
