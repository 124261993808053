import React from 'react';
import { Box, CircularProgress } from '@mui/material';

import { StatusBar } from 'components';

interface IPageProps {
  children: React.ReactNode
  hideStatusBar?: boolean
  loading?: boolean
}
export const Page = ({ children, hideStatusBar, loading }: IPageProps) => (
  <Box>
    {!hideStatusBar && <StatusBar />}
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      margin='5%'
    >
      {loading ? <CircularProgress /> : children}
    </Box>
  </Box>
);
