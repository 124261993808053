import React, { useEffect, useCallback, useState } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { debounce, intersection } from 'lodash';

import { HintText } from 'components';
import { store, moviedb, MovieInfo } from 'utils';

const MAX_MOVIE_SUGGESTIONS = 3;

interface AvailableOnOtherProvider {
  [tmdb: string]: string
}
interface IMoviePickerProps {
  label: string
  suggestedMovie: MovieInfo | null
  onSelect: (movie: MovieInfo | null) => void
}
export const MoviePicker = ({ label, onSelect, suggestedMovie }: IMoviePickerProps) => {
  const { room } = store.useState();
  const [movies, setMovies] = useState<(MovieInfo & { availableOnOtherProviderName?: string })[]>([]);
  const [availableOnOtherProvider, setAvailableOnOtherProvider] = useState<AvailableOnOtherProvider>({});
  const [searchText, setSearchText] = useState<string>('');
  const [requestText, setRequestText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedSetRequestText = useCallback(debounce(setRequestText, 250), []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (requestText) {
      (async () => {
        setLoading(true);
        const movieResponse = await moviedb.searchMovie({ query: requestText });
        const prioritizedMoviesList = movieResponse.results
          ?.sort((a, b) => (b.vote_count || 0) - (a.vote_count || 0))
          ?.slice(0, MAX_MOVIE_SUGGESTIONS)

        const providersResponse = await Promise.all(
          prioritizedMoviesList?.map(({ id }) => moviedb.movieWatchProviders(id!)) || []
        )
        // if the movie searched is unavailable on currently selected
        // providers, show the user where they could find this movie.
        setAvailableOnOtherProvider(
          prioritizedMoviesList?.reduce((x, m) => {
            // get providers for this movie
            const availableProviders = providersResponse
              .find(({ id }) => id === m.id)
              ?.results?.US?.flatrate
              ?.filter(({ provider_id }) => provider_id)

            // do any of the movie's providers match with the selected
            // providers for this movie?
            if (
              intersection(availableProviders?.map(p => String(p.provider_id)), room?.providerIds).length === 0
              && m.id
            ) {
              const availableOn = availableProviders
                ?.sort((a, b) => (a.display_priority || 0) - (b.display_priority || 0))
                ?.[0]?.provider_name
              // set the providers
              return {
                ...x,
                [m.id]: availableOn,
              };
            }

            return x;
          }, {} as AvailableOnOtherProvider) || {}
        )
        // movies needs to be set after providers otherwise options
        // don't disable for a split second after they're loaded
        setMovies(prioritizedMoviesList || [])
        setLoading(false);

      })();
    } else {
      setMovies([])
      setAvailableOnOtherProvider({})
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [requestText])
    /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Autocomplete
      style={{ width: '100%' }}
      options={movies}
      filterOptions={m => m}
      loading={loading}
      isOptionEqualToValue={(o, m) => o.id === m.id}
      inputValue={searchText}
      onInputChange={(_, val) => {
        setSearchText(val);
        debouncedSetRequestText(val);
      }}
      value={suggestedMovie}
      onChange={(_, movie) => {
        onSelect(movie);
        setRequestText('');
      }}
      noOptionsText='No movies found'
      getOptionLabel={m => m.title || ''}
      getOptionDisabled={({ id }) => id! in availableOnOtherProvider}
      renderOption={(props, movie) => (
        <li {...props} key={movie.id}>
          <Typography>{movie.title}&nbsp;</Typography>
          {movie.release_date && (
            <HintText>{`(${movie.release_date.substring(0, 4)})`}</HintText>
          )}
          {movie.id! in availableOnOtherProvider && (
            <Typography>
              &nbsp;
              {
                availableOnOtherProvider[movie.id!]
                  ? `- on ${availableOnOtherProvider[movie.id!]}`
                  : 'not streaming'
              }
            </Typography>
          )}
        </li>
      )
      }
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth />
      )}
      autoComplete
    />
  )
}
